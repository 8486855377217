import Bowser from "bowser";
import $ from "jquery";
import "slick-carousel";
import MicroModal from "micromodal";

// MicroModal
MicroModal.init();

$(document).ready(function() {
  // Inside of this function, $() will work as an alias for jQuery()
  // and other libraries also using $ will not be accessible under this shortcut
  // https://codex.wordpress.org/Function_Reference/wp_enqueue_script#jQuery_noConflict_Wrappers

  // Touch Device Detection
  var isTouchDevice = "ontouchstart" in document.documentElement;
  if (isTouchDevice) {
    $("body").removeClass("no-touch");
  }

  // Browser detection via Bowser (https://github.com/lancedikson/bowser) - add detection as needed
  const userBrowser = Bowser.getParser(window.navigator.userAgent);
  const browser = userBrowser.getBrowser();

  if (browser.name === "Internet Explorer" && browser.version == "11.0") {
    $("body").addClass("ie-11");
  } else if (browser.name === "Safari") {
    $("body").addClass("safari");
  }

  // General site header functions
  // Mobile menu toggle
  function toggleMobileMenu( menuIcon, searchPanel, mobileNav ) {
    menuIcon.on("click", function() {
      // First close search if open
      if( searchPanel.css("display") == "block" ) {
        $("html").removeClass("locked");
        $("body").removeClass("locked masked");

        searchPanel.slideUp();
      }

      $("html").toggleClass("locked");
      $("body").toggleClass("locked masked");

      $(this).toggleClass("active");
      mobileNav.slideToggle();
    });
  }

  // Block link following on submenu parent links
  function mobileSubMenuLinks( parentLink, hasActiveState ) {
    parentLink.on("click", function(e) {
      e.preventDefault();

      $(this).siblings(".sub-menu").slideToggle();

      if( hasActiveState ) {
        $(this).toggleClass("active");
      }
    });
  }

  // Search panel toggle
  function toggleSearchPanel( searchIcon, menuIcon, navMobile, searchPanel ) {
    searchIcon.on("click", function() {
      // First close mobile nav if open
      if( navMobile.css("display") == "block" ) {
        $("html").removeClass("locked");
        $("body").removeClass("locked masked");

        menuIcon.removeClass("active");
        navMobile.slideUp();
      }

      $("html").toggleClass("locked");
      $("body").toggleClass("locked masked");

      searchPanel.slideToggle();
    });
  }

  // Resize behavior
  function resizeWindow( width, menuIcon, mobileNav, isDashboard, searchPanel ) {
    $(window).on("resize", function() {
      if( $(window).width() > width  && (mobileNav.css("display") == "block" || isDashboard) ) {
        $("html").removeClass("locked");
        $("body").removeClass("locked masked");

        menuIcon.removeClass("active");
        mobileNav.slideUp();

        if( isDashboard ) {
          searchPanel.slideUp();
        }
      }
    });
  }

  // Close panels on outside clicks
  function closePanels( parent, searchPanel ) {
    $(document).on("click", function(e) {
      if( !$(e.target).closest(parent).length ) {
        if( searchPanel.css("display") === "block" ) {
          $("html").removeClass("locked");
          $("body").removeClass("locked masked");

          searchPanel.slideUp();
        }
      }
    });
  }

  // Top site header functions
  if( $(".site-header").length ) {

    toggleMobileMenu( $(".site-header .menu-icon"), $(".site-header .search-panel"), $(".nav--mobile") );

    mobileSubMenuLinks( $(".nav--mobile .menu-item-has-children > a"), true );

    toggleSearchPanel( $(".site-header .search-icon"), $(".site-header .menu-icon"), $(".nav--mobile"), $(".site-header .search-panel") );

    resizeWindow( 1024, $(".site-header .menu-icon"), $(".nav--mobile"), false, null );

    closePanels( ".site-header", $(".site-header .search-panel") );

  }

  // Left site header functions
  if( $(".site-header--left").length ) {

    toggleMobileMenu( $(".site-header--left .menu-icon--dashboard"), $(".site-header--left > .search-panel"), $(".nav--dashboard-mobile") );

    mobileSubMenuLinks( $(".nav--dashboard .menu-item-has-children > a"), false );

    toggleSearchPanel( $(".site-header--left .search-icon--dashboard"), $(".site-header--left .menu-icon--dashboard"), $(".nav--dashboard-mobile"), $(".site-header--left > .search-panel") );

    resizeWindow( 767, $(".site-header--left .menu-icon--dashboard"), $(".nav--dashboard-mobile"), true, $(".site-header--left > .search-panel") );

    closePanels( ".site-header--left", $(".site-header--left > .search-panel") );

  }

  // Search panel post types
  $(".search--main input[type=radio]").on("change", function() {
    let value = $(this).attr("id");

    $(".search--main input[type=hidden]").val(value);
  });

  // Home hero slider
  if( $(".home-hero").length ) {
    $(".home-hero").slick({
      autoplay: false,
      arrows: true,
      dots: false,
      fade: true,
      slidesToShow: 1,
      adaptiveHeight: true,
    });
  }

  // Testimonial sliders
  if( $(".testimonials__slider").length ) {
    $(".testimonials__slider").slick({
      autoplay: false,
      arrows: true,
      dots: false,
      fade: true,
      slidesToShow: 1,
      adaptiveHeight: true,
    });
  }

  // Form Label Animation
  $(".gform_wrapper form .animated").find("input, select, textarea").on("focusin", function() {
  	$(this).parents(".animated").children("label").addClass("active-position active-weight active-color");

  	// Off focus
  	$(".gform_wrapper form .animated").find("input, select, textarea").on("focusout", function() {
  		if( $(this).val() !== "" ) { // Remove active weight and keep raised position if input has content
  			$(this).parents(".animated").children("label").removeClass("active-weight active-color");
  		} else { // Else remove both weight and raised position
  			$(this).parents(".animated").children("label").removeClass("active-position active-weight active-color");
  		}
  	});
  });

  // Accordions
  $(".accordion__header").on("click", function() {
    var icon = $(this).find(".accordion__icon i"),
      video = $(this).siblings(".accordion__body").find("iframe"),
      videoSrc = video.attr("src");

    if( icon.hasClass("fa-plus") ) {
      icon.removeClass("fa-plus").addClass("fa-minus");
    } else {
      icon.removeClass("fa-minus").addClass("fa-plus");
    }

    $(this).siblings(".accordion__body").slideToggle();

    // Reset video on accordion close
    if( video.length ) {
      video.attr("src", videoSrc);
    }
  });

  // Close dropdowns on outside click
  $(document).on("click", function(e) {
    if(!$(e.target).closest(".dropdown").length) {
      if($(".dropdown__menu").css("display") === "block") {
        $(".dropdown__parent").removeClass("open");
        $(".dropdown__menu").slideUp();
      }
    }
  });

  // Dropdowns
  $(".dropdown__parent").on("click", function() {
    $(this).toggleClass("open");
    $(this).siblings(".dropdown__menu").slideToggle();
  });

  $(".dropdown__parent").on("keyup", function(e) {
    // Enter key
    if( e.which == 13) {
      $(this).toggleClass("open");
      $(this).siblings(".dropdown__menu").slideToggle();
    }

    // Esc key
    if( e.which == 27) {
      $(this).removeClass("open");
      $(this).siblings(".dropdown__menu").slideUp();
    }
  });

  // Close dropdown on selection
  $(".dropdown__menu a").on("click", function() {
    $(this).parents(".dropdown").find(".dropdown__parent").removeClass("open");
    $(this).parents(".dropdown__menu").slideUp();
  });

  // Membership plan switching
  if( $(".price-grid").length ) {
    $(".price-grid .switch input[type='checkbox']").on("click", function() {
      $(this).parents(".price-grid__toggle").siblings(".price-grid__prices").toggleClass("active");
      $(this).parents(".price-grid__content").siblings(".price-grid__button").toggleClass("active");
    });
  }

  // Membership plan switching (v2)
  if( $(".pricing-row").length ) {
    $(".pricing-row .switch input[type='checkbox']").on("click", function() {
      $(this).parents(".pricing-row__toggle").siblings(".pricing-row__price").toggleClass("active");
      $(this).parents(".pricing-row").find(".pricing-row__button").toggleClass("active");
    });
  }

});
